<template>
  <nuxt-link
    :to="link"
    class="vz-home-news-card"
  >
    <NuxtImg
      v-if="isShowImg"
      class="vz-home-news-card-img"
      :src="src"
      :alt="item.title"
      loading="lazy"
    />

    <div
      class="vz-home-news-card__block"
      :class="{'vz-home-news-card__block-img': isShowImg}"
    >
      <vz-icon
        class="vz-home-news-card__arrow"
        name="arrow-top-right-thick"
        no-masked-icon
        size="24"
      />
      <div class="vz-home-news-card__date">{{ date }}</div>
      <div class="vz-home-news-card__title">{{ item.title }}</div>
      <div class="vz-home-news-card__description">{{ item.announce }}</div>
    </div>
  </nuxt-link>
</template>

<script setup lang="ts">
import {getFullDate} from '~/common/date';
import {PropType} from 'vue';
import {EventRecord} from '~/types/components/personal/actions';
import {BASE_URL_HTTPS} from '~/composables/useVzFetch';

const props = defineProps({
  item: {
    type: Object as PropType<EventRecord>,
    default: () => ({})
  },
  isShowImg: {
    type: Boolean,
    default: true
  }
})

const date = computed(() => {
  return getFullDate(props.item.date)
})

const src = computed(() => {
  return props.item.imageUrls?.main || (BASE_URL_HTTPS + props.item.img)
})

const link = computed(() => {
  const tag = props.item?.is_action ? 'actions' : 'news'
  return `/${tag}/${props.item.href}/`
})
</script>

<style scoped lang="scss">
.vz-home-news-card {
  $img-height: 275px;
  $transition: all 0.3s ease;

  img {
    display: block;
    width: 100%;
    height: 275px;
    object-fit: cover;
  }

  &__arrow {
    position: absolute;
    top: 20px;
    right: 20px;
    color: transparent;
    transition: $transition;
  }

  &__block {
    height: 100%;
    position: relative;
    border: 1px solid $border-gray;
    padding: 20px;

    &-img {
      border-top: none;
      height: calc(100% - $img-height);
    }

    &::after {
      content: '';
      position: absolute;
      left: -1px;
      bottom: -1px;
      height: 100%;
      width: calc(100% + 2px);
      border-bottom: 2px solid transparent;
      pointer-events: none;
      transition: $transition;
    }
  }

  &__date {
    font-size: $fs-14;
    color: $font-lower;
    font-weight: 400;
  }

  &__title {
    font-size: $fs-20;
    color: $font-dark;
    font-weight: 500;
    margin-top: 20px;
  }

  &__description {
    margin-top: 10px;
    color: $font-low;
  }

  :not(.vz-home-news-card__block-img) {
    .vz-home-news-card__title {
      font-size: $fs-16;
    }

    .vz-home-news-card__description {
      font-size: $fs-14;
      line-height: 20px;
    }

    @media screen and (max-width: $pub-sm) {
      .vz-home-news-card__description {
        display: none;
      }
    }
  }

  &:hover {
    text-decoration: unset;


    .vz-home-news-card__block {
      &::after {
        border-bottom: 2px solid $primary;
      }

      .vz-home-news-card__arrow {
        color: $primary;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.vz-home-news-card-img {
  view-transition-name: selected-news-card;
  contain: layout;
}
</style>
