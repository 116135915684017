<template>
  <div id="info" class="home-info">
    <h1 class="mb-25">{{ title }}</h1>
    <vz-switcher
      class="home-info-switcher"
      v-model="tab"
      public-style
      :items="tabItems"
      @click="onClick"
    />

    <div class="home-info-tabs">
      <vz-home-info-card
        v-for="(item, index) of cardItems[tab]"
        :key="index"
        :item="item"
      />
    </div>

    <div class="home-info-link mb-60 mt-20">
      <nuxt-link
        to="/services/"
        class="text-decoration-underline"
      >
        Все услуги
      </nuxt-link>
    </div>

    <public-get-full-info
      v-if="tab === 'business' || tab === 'partner'"
      :button-text="feedbackButtonText"
      :department-code="feedbackType"
      class="mb-60"
    />

    <vz-home-info-seo is-prepend />

    <div class="home-info-grid">
      <div
        class="home-info-grid-item"
        v-for="(item, index) of items"
        :key="index"
      >
        <vz-icon
          :url="item.imgUrl"
          variant="primary"
          size="50"
          no-masked-icon
        >
        </vz-icon>
        <div v-html="item.text" />
      </div>
    </div>

    <vz-home-info-seo />
  </div>
</template>

<script setup lang="ts">
import VzIcon from "~/components/ui-kit/vz-icon.vue";
import VzSwitcher from "~/components/ui-kit/vz-switcher.vue";
import VzHomeInfoSeo from "~/components/public/home/vz-home-info/vz-home-info-seo.vue";
import VzHomeInfoCard from "~/components/public/home/vz-home-info/vz-home-info-card.vue";
import {useCityStore} from "~/stores/city";
import {scrollToElem} from "~/common/functions";
import PublicGetFullInfo from '~/components/public/public-get-full-info.vue';
import useMenuItems from "~/composables/useMenuItems";

const {isHideInsurance} = useMenuItems()
const {currentCity} = useCityStore()

const tab = ref('individual')
const tabItems = [
  {
    title: 'Частным лицам',
    value: 'individual'
  },
  {
    title: 'Бизнесу',
    value: 'business'
  },
  {
    title: 'Интернет-магазинам',
    value: 'eshop'
  },
  {
    title: 'Партнёрам',
    value: 'partner'
  }
]

const from = computed(() => {
  return currentCity.prepositional_name2 ? `из ${currentCity.prepositional_name2}` : `из г. ${currentCity.name}`
})

const cardItems = computed(() => {
  const result = {
    'individual': [
      {
        to: '/address-delivery/',
        iconUrl: '/svg/icon/vz-home.svg',
        title: 'Адресная доставка',
        description: 'Отправить груз транспортной компанией от двери до двери, если нет времени или возможности заехать на терминал',
        imgUrl: '/images/pages/home/hand-boxes.png'
      },
      {
        to: '/shippingtypes/personalitemstransportation/',
        iconUrl: '/svg/package.svg',
        title: 'Перевозка вещей',
        description: 'Перевозка вещей при переезде по России из одного города в другой',
        imgUrl: '/images/pages/home/box-polyethylene.png'
      },
      {
        to: '/shippingtypes/furniture/',
        iconUrl: '/svg/box-transportation.svg',
        title: 'Перевозка мебели',
        description: 'Доставка всех видов мебели со склада или магазина, а также при переездах',
        imgUrl: '/images/pages/home/cargo2.png'
      },
      {
        to: '/payment-methods/',
        iconUrl: '/svg/payment-cards.svg',
        title: 'Доставка посылок',
        description: 'Доставка небольших грузов по России, размеры которых не превышают 80х40х40см',
        imgUrl: '/images/pages/home/pos-terminal.png'
      }
    ],
    'business': [
      {
        to: '/networkshops/',
        iconUrl: '/svg/cart.svg',
        title: 'Доставка в торговые сети и маркетплейсы',
        description: 'Бесплатный простой автомобиля, удобный график отправки и тарифы по запросам клиента',
        imgUrl: '/images/pages/home/cart-marcetplace.png'
      },
      {
        to: '/terminals/',
        iconUrl: '/svg/doc.svg',
        title: 'Грузоперевозки по России',
        description: 'Грузоперевозки по более 500 направлениям в РФ: межтерминальные, от двери до двери и от терминала до двери',
        imgUrl: '/images/pages/home/e-doc-management.png'
      },
      {
        to: '/shippingtypes/oversized/',
        iconName: 'shield',
        title: 'Перевозка крупногабаритных грузов',
        description: 'Отправления, параметры которых больше допустимых размеров',
        imgUrl: '/images/pages/insurance/vz-cargo-umbrella.png'
      },
      {
        to: '/delivery-russia/',
        iconUrl: '/svg/vz-capital.svg',
        title: 'Сборные грузоперевозки',
        description: 'Перевозка сборных грузов - простой и эффективный способ снижения стоимости доставки',
        imgUrl: '/images/pages/online-store/spb_moscow_delivery.png',
        imgStyle: 'object-fit: contain; padding: 20px 0;'
      }
    ],
    'eshop': [
      {
        to: '/online-store/',
        iconUrl: '/svg/boxes.svg',
        title: 'Доставка для интернет-магазинов',
        description: 'Выполняем доставку товаров в оптимальные сроки, расширяем географию присутствия',
        imgUrl: '/images/pages/home/box-clock.png'
      },
      {
        to: '/guide-lk/',
        iconUrl: '/svg/vz-phone-success-2.svg',
        title: 'Онлайн-управление',
        description: 'Загрузка до 50 заявок через Excel одновременно и другие возможности личного кабинета',
        imgUrl: '/images/pages/home/show-phone.png'
      },
      {
        to: '/cash-on-delivery/',
        iconUrl: '/svg/vz-coins.svg',
        title: 'Наложенный платёж',
        description: 'Оформление заказа со способом оплаты при получении и быстрым возвратом денежных средств Отправителю',
        imgUrl: '/images/pages/home/cash-on-delivery.png'
      },
      {
        to: '/safe-custody/',
        iconUrl: '/svg/vz-safe.svg',
        title: 'Бесплатное хранение',
        description: 'Хранение грузов клиентов на складе компании бесплатно до 14 дней, доступно ответственное хранение',
        imgUrl: '/images/pages/home/safe.png'
      }
    ],
    'partner': [
      {
        to: 'https://vozovoz.partners/',
        iconUrl: '/svg/car/truck.svg',
        title: 'Перевозчикам',
        description: 'Подробные условия для сотрудничества и работы с компанией Vozovoz',
        imgUrl: '/images/pages/home/trailer.png'
      },
      {
        to: '/franchise/',
        iconUrl: '/svg/vz-certificate.svg',
        title: 'Франшиза',
        description: 'Открытие и расширение бизнеса под известным брендом транспортных услуг',
        imgUrl: '/images/pages/home/rubik-cube.png'
      }
    ]
  }

  if (isHideInsurance) {
    const findInsurance = result.business.findIndex((val) => val.to === '/insurance/')
    result.business.splice(findInsurance, 1)
  }

  return result
})

const items = computed(() => {
  return [
    {
      imgUrl: '/svg/vz-time-success.svg',
      text: '<h3>Оптимально быстрая доставка</h3> в удобный Получателю промежуток времени'
    },
    {
      imgUrl: '/svg/vz-world.svg',
      text: `<h3>Широкая география перевозок по РФ</h3>, а также по Беларуси и Казахстану`
    },
    {
      imgUrl: '/svg/balloon-sms.svg',
      text: '<h3>Получение груза по SMS</h3> по коду без доверенности и дополнительных документов'
    },
    {
      imgUrl: '/svg/vz-search-success.svg',
      text: '<h3>Отслеживание местонахождения груза</h3> с момента отправления'
    },
    {
      imgUrl: '/svg/payment-cards.svg',
      text: '<h3>Оплата перевозки груза онлайн</h3> на сайте, в мобильном приложении или по QR-коду'
    },
    {
      imgUrl: '/svg/information-variant-circle.svg',
      text: '<h3>Полная информация о стоимости перевозки</h3> и ее сроках на этапе оформления заказа'
    }
  ]
})

const title = computed(() => {
  const city = currentCity.translit !== 'moskva' && currentCity.prepositional_name ? `в ${currentCity.prepositional_name}` : ''
  return `Транспортная компания ${city} VOZOVOZ`
})

const feedbackType = computed(() => {
  return tab.value === 'business' ? 'becomeAClient' : 'partnership'
})

const feedbackButtonText = computed(() => {
  switch (feedbackType.value) {
    case 'partnership':
      return 'Стать партнером'
    case 'becomeAClient':
    default:
      return 'Коммерческое предложение'
  }
})

function onClick() {
  scrollToElem('#info', {behavior: 'smooth'})
}
</script>

<style lang="scss" scoped>
@use "@/assets/sections/public/home-info.scss";
</style>
