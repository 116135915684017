<template>
  <div class="home-banner">
    <div class="home-banner-img" />
    <!--snow v-if="isSnowMode"/-->
    <div class="public-container flex flex-end">
      <div class="home-banner-calculator">
        <vz-calculator is-main />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
//import Snow from '~/components/public/snow/snow.vue';
import VzCalculator from "~/components/public/order/vz-calculator.vue";
import {isDevServer} from '~/composables/useVzFetch';
import {useWindowSize} from '@vueuse/core';
import {storeToRefs} from 'pinia';
import {useCityStore} from '~/stores/city';

const { from, currentCity } = storeToRefs(useCityStore())
const { width } = useWindowSize()

/*
const isSnowMode = computed(() => {
  const date = new Date();
  const month = date.getMonth()

  return isDevServer
    ? month >= 10 || month < 2
    : month >= 11 || month < 2
})
*/

watch(() => currentCity.value.guid, () => {
  from.value = currentCity.value
})
</script>

<style lang="scss" scoped>
.home-banner {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-img {
    position: absolute;
    display: flex;
    min-height: 100%;
    width: 1920px;
    max-width: 100%;
    background-size: cover;
    background-image: url(/images/pages/home/banner.webp);

    @media screen and (max-width: 1250px) {
      width: 100%;
      background-image: url(/images/pages/home/banner_md.webp) !important;
    }

    @media screen and (max-width: $pub-sm) {
      background-image: url(/images/pages/home/banner_sm.webp) !important;
      height: 240px;
      width: 768px;
      position: relative;
      background-position-x: 0;
    }

    @media screen and (max-width: $pa-xs) {
      display: none;
    }
  }

  @media screen and (max-width: $pub-sm) {
    flex-direction: column;
  }

  &-calculator {
    width: 760px;
    opacity: 0.97;
    margin-top: 10px;
    margin-bottom: 10px;
    z-index: 1;
    box-shadow: $pub-dark-gray-box-shadow;
    border-radius: 6px;

    @media screen and (max-width: $pub-md) {
      box-shadow: none;
    }

    @media screen and (max-width: $pub-sm) {
      width: 100%;
      height: 100%;
      margin-top: 0;
    }

    :deep(.vz-calculator) {
      @media screen and (max-width: $pub-xs) {
        margin-top: 20px;
      }
    }
  }
}
</style>
