<template>
  <div class="home-info-seo">
    <template v-if="isPrepend">
      <h2 class="vz-text-align-left text-thin">Грузоперевозки с транспортной компанией "Возовоз"</h2>
      <p class="mb-50">
        "Возовоз" — ваш надежный партнер по перевозкам в России, Беларуси и Казахстане. Мы специализируемся на доставке 
        сборных грузов, что позволяет вам экономить на транспортных услугах, даже если ваш груз небольшой. Сборные перевозки 
        – это способ объединения нескольких грузов от разных отправителей в одну машину, что значительно снижает стоимость 
        доставки грузов по сравнению с отдельной отправкой.
      </p>
      <h3>Коммерческие грузоперевозки</h3>
      <p>
        Нужно перевезти груз в России или за ее пределы? Мы предлагаем комплексные логистические решения для бизнеса 
        любого масштаба.<br>
        Компания Возовоз предоставляет услуги доставки грузов для:
      </p>
      <ul class="public-list public-list-square">
        <li v-for="el in list">
          <span>{{ el }}</span>
        </li>
      </ul>
      <p class="mb-50">
        Наши услуги включают в себя погрузку-разгрузку, упаковку и страхование груза, гарантируя сохранность любых 
        отправлений. Перевозка грузов по России с Возовоз – это надежность и высокая скорость доставки.
      </p>
      <h3>Цены на грузоперевозки</h3>
      <p>
        Клиенты Возовоз знают точную стоимость перевозки уже при оформлении заказа. Рассчитать точную стоимость доставки легко 
        следующими способами:
      </p>
      <ul class="public-list public-list-square">
        <li v-for="item in items">
          <span>
            {{ item.text }}
            <nuxt-link :to="item.link.to" target="_blank">
              {{ item.link.title }}
            </nuxt-link>
          </span>
        </li>
      </ul>
    </template>
    <template v-else>
      <h3>Междугородние перевозки</h3>
      <p class="mb-50">
        Планируете переезд из города в город? Или нужно перевезти груз между населенными пунктами РФ, Беларуси или Казахстана?
        Мы предлагаем удобную и быструю грузоперевозку по межгороду. Благодаря обширной сети филиалов компании и отработанных 
        маршрутов, мы обеспечиваем доставку по всей России. Доступна доставка от двери до двери, от терминала до двери, а также 
        межтерминальные перевозки. Мы доставляем грузы для выдачи в пункты Яндекс Маркета. Транспортная компания Возовоз обеспечит 
        перевозку крупногабаритных грузов по РФ, а также международные грузоперевозки в Китай в кратчайшие сроки. 
      </p>
      <h3>Терминалы транспортной компании</h3>
      <p class="mb-50">
        Компания «Возовоз» имеет широкую 
        <nuxt-link :to="{ name: 'contacts', params: { tag: 'address' } }" target="_blank">сеть филиалов</nuxt-link> - 
        {{ terminalList?.length }} отделений в 79 городах РФ, а также в Беларуси и Казахстане.
      </p>
      <h3>Условия предоставления услуг</h3>
      <p class="mb-10">
        Мы принимаем к перевозке обычные, крупногабаритные и малогабаритные грузы, обеспечивая их безопасную и своевременную 
        транспортировку. Чтобы стать клиентом транспортной компании "Возовоз", оформите заявку на сайте vozovoz.ru или в мобильном 
        приложении.
      </p>
      <p>
        Стоимость перевозки определяется параметрами груза, тарифами и дополнительными опциями в заказе. Клиенту необходимо обеспечить 
        необходимые документы для отправки груза:
      </p>
      <ul class="public-list public-list-square">
        <li v-for="doc in docs">
          <span>{{ doc }}</span>
        </li>
      </ul>
      <p class="mb-20">
        Транспортная компания Возовоз не принимает запрещенные к перевозке грузы – смотрите 
        <nuxt-link :to="{ name: 'prohibited-goods' }" target="_blank">полный перечень</nuxt-link>.
      </p>
    </template>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useTerminalStore } from '~/stores/terminal';

const props = defineProps({
  isPrepend: {
    type: Boolean,
    default: false
  }
})

const { terminalList } = storeToRefs(useTerminalStore())

const list = [
  'интернет-магазинов,',
  'маркетплейсов и торговых сетей,',
  'крупного и среднего бизнеса,',
  'частных лиц.'
]

const docs = [
  'юридическим лицам – доверенность, паспорт отправителя и документы на груз,',
  'физическим лицам – паспорт и опись груза.'
]

const items = [
  {
    text: 'используйте наш ',
    link: { 
      title: 'калькулятор стоимости',
      to: { name: 'order-create' }
    }
  },
  {
    text: 'посмотрите ',
    link: {
      title: 'Тарифы на грузоперевозки',
      to: { name: 'tariffs' }
    }
  }
]
</script>

<style lang="scss" scoped>
.home-info-seo {
  h2, h3 {
    font-weight: 400 !important;
    color: $font-dark !important;
  }

  h3 {
    margin-bottom: 20px !important;
  }

  ul.public-list {
    margin-bottom: 10px;

    span {
      color: $font-low;
    }
  }

  a {
    text-decoration: underline;
  }
}
</style>
