<template>
  <div class="home-news mb-60">
    <div class="home-news-buttons mb-30">
      <vz-switcher
          v-model="tab"
          public-style
          :items="tabItems"
      />
    </div>

    <div class="home-news-grid">
      <template v-if="itemsList.length">
        <vz-home-news-card
            v-for="(item, index) of itemsList"
            :key="item.id"
            :item="item"
            :isShowImg="!index"
        />
      </template>
      <template v-else>
        <vz-skeleton v-for="(value, index) of 5" :key="value" :height="!index ? 420 : 200" />
      </template>
    </div>

    <div class="home-news-link mt-20">
      <nuxt-link
          :to="link"
          class="text-decoration-underline"
      >
        {{ tab === 'news' ? 'Все новости' : 'Все акции' }}
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useNewsStore} from "~/stores/news";
import VzHomeNewsCard from "~/components/public/home/vz-home-news-card.vue";
import VzSwitcher from "~/components/ui-kit/vz-switcher.vue";
import VzSkeleton from "~/components/ui-kit/vz-skeleton.vue";

const newsStore = useNewsStore()
await newsStore.fetchNews(0);

const tab = ref('news');
const tabItems = [
  {
    title: 'Новости',
    value: 'news'
  },
  {
    title: 'Акции',
    value: 'actions'
  },
]

const itemsList = computed(() => {
  return tab.value === 'news' ? newsStore.lastFiveNews : newsStore.lastFiveActions;
})

const link = computed(() => {
  return `/${tab.value}/`
})

watch(tab, (val) => {
  if (val === 'actions' && !newsStore.lastFiveActions.length) {
    newsStore.fetchNews(1);
  }
})
</script>

<style lang="scss" scoped>
.home-news {
  &-buttons {
    display: flex;
    gap: 20px;
    width: calc(50% - 10px);

    @media screen and (max-width: $pub-sm) {
      width: 500px;
    }
    @media screen and (max-width: $pub-xs) {
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
    }
  }

  &-flex {
    display: flex;
    gap: 20px;
  }

  &-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    @media screen and (min-width: $pub-sm + 1) {
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      a, div {
        &:nth-child(1) {
          grid-area: 1 / 1 / 3 / 3;
        }

        &:nth-child(2) {
          grid-area: 1 / 3 / 2 / 4;
        }

        &:nth-child(3) {
          grid-area: 1 / 4 / 2 / 5;
        }

        &:nth-child(4) {
          grid-area: 2 / 3 / 3 / 4;
        }

        &:nth-child(5) {
          grid-area: 2 / 4 / 3 / 5;
        }
      }
    }

    @media screen and (max-width: $pub-sm) and (min-width: $pub-xs + 1) {
      grid-template-columns: repeat(2, 1fr);
      a, div {
        &:nth-child(1) {
          grid-area: 1 / 1 / 5 / 2;
        }

        &:nth-child(2) {
          grid-area: 1 / 2 / 2 / 3;
        }

        &:nth-child(3) {
          grid-area: 2 / 2 / 3 / 3;
        }

        &:nth-child(4) {
          grid-area: 3 / 2 / 4 / 3;
        }

        &:nth-child(5) {
          grid-area: 4 / 2 / 5 / 3;
        }
      }
    }
  }

  &-link {
    font-size: $fs-18;
    text-align: right;
  }
}
</style>
